<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考点管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ lastPageData.centerId?'编辑':'新增' }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" class="ruleForm" :rules="rules" :model="ruleForm" label-width="100px" size="small"
              style="width: 500px;">
              <div class="fromTitle">基本信息</div>
              <el-form-item label="考点名称" prop="centerName">
                <el-input type="text" v-model="ruleForm.centerName" clearable placeholder="请输入考点名称" />
              </el-form-item>
              <el-form-item label="企业名称" prop="compId">
                <el-select size="small" v-model="ruleForm.compId" remote :remote-method="getCompanyList" filterable
                           clearable placeholder="请至少输入两个字搜索" class="width_w">
                  <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                             :value="item.compId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="考点地址" prop="address">
                <el-input type="textarea" resize="none" show-word-limit maxlength="200" rows="4"
                  v-model="ruleForm.address" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="$router.back()" class="bgc-bv">取 消</el-button>
                <el-button class="bgc-bv" @click="sureData">确 定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "systemManage_examCenter_addAndedit",
  data() {
    return {
      // 上个页面的数据
      lastPageData: {
        centerId: "", // 考点id
      },
      // 基本信息
      ruleForm: {
        centerName: "", // 考点名称
        compId: "",     // 所属单位id
        address: "",    // 考点地址
      },
      companyList: [],  //企业列表
      // 基本信息 - 数据校验
      rules: {
        centerName: [
          { required: true, trigger: "change", message: "请输入考点名称" },
        ],
        compId: [
          { required: true, trigger: "change", message: "请输入所属企业" },
        ],
        address: [
          { required: true, trigger: "change", message: "请输入考点地址" },
        ],
      },
    };
  },
  created() {
    this.lastPageData.centerId = this.$route.query?.centerId ?? "";
    if(this.lastPageData.centerId){
      this.getDetail();
    }
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 详情
    getDetail() {
      this.$post("/biz/center/pa-test-center/getCenter", {
        centerId: this.lastPageData.centerId,
      }, 3000, true, 10).then((res) => {
        if ("0" === res.status) {
          this.ruleForm.centerId = res.data.centerId;
          this.ruleForm.centerName = res.data.centerName;
          this.ruleForm.address = res.data.address;
          this.$post("/biz/company/get/info",
              {compId: res.data.compId},
              3000, true, 10)
              .then((res) => {
                if ("0" === res.status) {
                  this.companyList = [{
                    compId: res.data.compId,
                    compName: res.data.compName,
                  }]
                  this.ruleForm.compId = res.data.compId;
                }
              })
        }
      });
    },
    // 获取 - 企业名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
            .then((res) => {
              if (res.status == 0) {
                this.companyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.companyList = [];
      }
    },
    // 确定
    sureData() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let url = '';
          if(this.lastPageData.centerId){
            url = '/biz/center/pa-test-center/modify'
          } else {
            url = '/biz/center/pa-test-center/insert'
          }
          this.$post(url, this.ruleForm, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功')
              this.$router.back();
            })
            .catch((err) => {
              return;
            });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.ruleForm {
  .fromTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
</style>